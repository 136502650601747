<template>
  <el-cascader
      v-model="value"
      :disabled="disabled"
      :placeholder="placeholder"
      :size="size"
      :options="options"
      :popper-class="classed"
      loading
      :level="level"
      :props="{ checkStrictly: changeonselect }"
      @change="handleChange"
      clearable
      :show-all-levels="show_level"
  ></el-cascader>
</template>

<script>
export default {
  name: "scascader",
  props: [
    "defaultValue",
    "placeholder",
    "codeType",
    "codeLevel",
    "codeValue",
    "show_level",
    "size",
    "disabled",
    "changeonselect",
    "level",
    "dictValue",
    "classed",
  ],
  data() {
    return {
      value: [],
      options: [],
      mlevel: ""
    };
  },
  watch: {
    defaultValue: {
      handler: function (val) {
        // for (let i = 0; i < val.length; i++) {
        //   if (val[i]) {
        //     this.value.push(val[i]);
        //   }
        // }
        this.value = val;
      }
    }
  },
  methods: {
    handleChange(value) {
      this.$emit("setMValue", value);
    },
    async initDictData() {
      //根据字典Code, 初始化字典数组
      let params = {
        dictCode: this.codeType,
        maxLevel: this.codeLevel,
        dictValue: this.dictValue,
      };
      let _this = this;
      await this.$axios
          .get("/api/app-jycy-sysdict/commonDictValue", params)
          .then(res => {
            if (res.data.success) {
              if (params.dictValue === 'p20') {
                _this.options.push({label: '河南', value: 'p20', children: ''});
                _this.options[0].children = res.data.data;
                return
              }
              _this.options = res.data.data;
            }
          });
    }
  },
  created() {
    this.initDictData();
    // if (this.defaultValue) {
    //   console.log(this.defaultValue)
    //   for (let i = 0; i < this.defaultValue.length; i++) {
    //     if (this.defaultValue[i]) {
    //       this.value.push(this.defaultValue[i]);
    //     }
    //   }
    // }
    // console.log(this.defaultValue)
    // console.log(this.value)
  }
};
</script>
