<template>
  <div class="retrieve">
    <div class="content">
      <div class="title">
        <h3>兰州市残疾人就业创业网络服务平台 | 账号安全</h3>
        <router-link to="/">返回首页</router-link>
      </div>
      <div class="tab">
        <h1>通过以下流程找回密码</h1>
        <ul class="ul">
          <li class="active">
            <img src="~/static/login/01_active.png" alt="" />
            <h3>通过以下流程找回密码</h3>
          </li>
          <li class="active">
            <img src="~/static/login/02_active.png" alt="" />
            <h3>验证信息</h3>
          </li>
          <li :class="active == 1 ? 'active' : ''">
            <img
                :src="
                active == 1
                  ? require('@/static/login/03_active.png')
                  : require('@/static/login/03.png')
              "
                alt=""
            />
            <h3>重置密码</h3>
          </li>
        </ul>
      </div>
      <el-form
          :model="form"
          :rules="error"
          label-width="120px"
          ref="form"
          class="form"
          :hide-required-asterisk="true"
          v-if="active == 0"
      >
        <el-form-item label="姓名" prop="name">
          <el-input
              v-model="form.name"
              placeholder="请输入真实姓名"
              class="wrap_input w336"
          ></el-input>
        </el-form-item>
        <el-form-item label="身份证" prop="dcNumber">
          <el-input
              v-model="form.dcNumber"
              placeholder="请输入身份证号"
              class="wrap_input w336"
          ></el-input>
        </el-form-item>
        <el-form-item label="残疾类别/等级" prop="distype">
          <ThreeLink
              codeType="disabled_type"
              codeLevel="2"
              placeholder="请选择残疾类别"
              class="w336 wrap_input"
              @setMValue="setScasValue($event, form, ['distype', 'dislv'])"
              :defaultValue="[form.distype, form.dislv]"
          ></ThreeLink>
        </el-form-item>
        <el-form-item label="手机号码" prop="iphone">
          <el-input
              v-model="form.iphone"
              placeholder="请输入手机号码"
              class="wrap_input w336"
          ></el-input>
        </el-form-item>
        <el-form-item class="btn">
          <el-button type="primary" plain class="w180" @click="goRouter"
          >上一步</el-button
          >
          <el-button type="primary" class="w180" @click="nextSubmit"
          >下一步</el-button
          >
        </el-form-item>
      </el-form>
      <el-form
          :model="form"
          label-width="120px"
          ref="form"
          class="form"
          :hide-required-asterisk="true"
          v-if="active == 1"
      >
        <el-form-item label="设置密码" prop="pwd">
          <el-input
              v-model="form.pwd"
              type="password"
              placeholder="请设置新密码"
              class="wrap_input w336"
          ></el-input>
        </el-form-item>
        <el-form-item label="确认密码">
          <el-input
              v-model="form.newPass"
              type="password"
              placeholder="请再次输入密码"
              class="wrap_input w336"
          ></el-input>
        </el-form-item>
        <el-form-item class="btn">
          <el-button type="primary" plain class="w180" @click="active = 0"
          >上一步</el-button
          >
          <el-button type="primary" class="w180" @click="submit"
          >立即提交</el-button
          >
        </el-form-item>
      </el-form>
    </div>
  </div>
</template>

<script>
import ThreeLink from "@/components/selectComponents/ThreeLink";
export default {
  name: "phone",
  components: { ThreeLink },
  data() {
    return {
      form: {
        name: "",
        dcNumber: "",
        iphone: "",
        disabledType: "",
        distype: "",
        dislv: "",
        pwd: "",
        newPass: "",
      },
      error: {
        iphone: [
          {
            required: true,
            message: "请输入您的手机号码",
            trigger: "blur",
          },
          {
            pattern: /^1[3456789]\d{9}$/,
            message: "手机号格式不对",
            trigger: "blur",
          },
        ],
        iphoneCode: [
          { required: true, message: "请输入收到的验证码", trigger: "blur" },
        ],
        pwd: [
          { required: true, message: "您设置的密码有误！", trigger: "blur" },
          {
            pattern: /^[a-zA-Z\s\d\.~!@#$%\^&\*\(\)-_=+\\\{\}\]:;"',/\?]{8,}$/,
            message:
                "密码必须为8位以上，且必须包含数字、字母、特殊字符",
            trigger: "blur",
          },
        ],
      },
      btntxt: "获取验证码",
      time: 180,
      getCodeBtnDisable: false,
      active: 0,
    };
  },
  methods: {
    nextSubmit() {
      this.$refs.form.validate(async (valid) => {
        if (!valid) {
          this.$message.error("信息填写不完整");
        } else {
          this.active = 1;
        }
      });
    },
    submit() {
      this.$refs.form.validate((valid) => {
        if (valid) {
          if (this.form.newPass != this.form.pwd) {
            this.$message({
              message: "两次密码不一致，请重新输入",
              center: true,
              type: "warning",
            });
            return;
          } else {
            let that = this;
            let params = {
              name:this.form.name,
              idNumber: this.$sm2Encrypt(this.form.dcNumber),
              iphone: this.form.iphone,
              distype: this.form.distype,
              dislv: this.form.dislv,
              pwd: this.$sm2Encrypt(this.form.pwd),
            };
            that.$api.verifyDisabledCode(params).then(function (res) {
              if (!res.data.success) {
                that.$message.warning(res.data.msg);
              } else {
                that.$message({
                  message: "密码修改成功",
                  center: true,
                  type: "success",
                });
                this.$router.push("/");
              }
            });
          }
        } else {
          this.$message.error("信息填写不完整");
        }
      });
    },
    setScasValue(event, form, keys) {
      for (let i = 0; i < keys.length; i++) {
        let value = event[i];
        if (!value) {
          value = "";
        }
        let key = keys[i];
        form[key] = value;
      }
    },
    goRouter() {
      this.$router.push("/retrievePassword");
    },
  },
};
</script>

<style scoped lang="less">
.retrieve {
  width: 100%;
  min-height: 100vh;
  background: white;

  .w336 {
    width: 336px;
  }

  .content {
    width: 1200px;
    margin: 0 auto;
    padding-bottom: 100px;

    .title {
      display: flex;
      padding-top: 26px;

      h3 {
        flex: 1;
        font-size: 18px;
        color: #28a46d;
      }

      a {
        font-size: 18px;
        color: #66716c;
      }

      a:hover {
        color: #28a46d;
      }
    }

    .tab {
      width: 635px;
      margin: 0 auto;
      padding-top: 60px;

      h1 {
        font-size: 28px;
        padding-bottom: 36px;
        text-align: center;
      }

      .ul {
        display: flex;

        li {
          position: relative;
          flex: 1;
          text-align: center;

          h3 {
            font-size: 18px;
            margin-top: 6px;
            color: #66716c;
          }
        }

        li.active h3 {
          color: #28a46d;
        }

        li::after {
          content: "";
          position: absolute;
          top: 50%;
          left: -50px;
          transform: translateY(-50%);
          width: 102px;
          height: 2px;
          background: #ccd0ce;
        }
        li.active::after {
          background: #28a46d;
        }
        li:first-child::after {
          display: none;
        }
      }
    }
    /deep/ .form {
      width: 792px;
      margin: 26px auto 0 auto;
      padding: 36px 109px;
      .el-form-item {
        margin-bottom: 36px;
        .el-input__inner::placeholder {
          font-size: 14px;
          color: #99a09d;
        }

        .el-input-group--append {
          border: 1px solid #cecfd0;
          border-radius: 6px;

          .el-input__inner {
            border: none;
          }
        }
        .el-input-group__append {
          border: none;
          padding: 0 10px;
          background: rgba(0, 0, 0, 0.03);

          span {
            display: inline-block;
            font-size: 14px;
            color: #66716c;
            padding-left: 20px;
            border-left: 1px solid rgba(0, 0, 0, 0.1);
          }

          .el-button.is-disabled,
          .el-button.is-disabled:hover,
          .el-button.is-disabled:focus {
            border: none;
            background: none;
            color: #00924b;
          }
        }
      }
      .btn {
        margin-top: 126px;
        transform: translateX(-24px);
        .el-button {
          margin-right: 24px;
        }
      }
    }
  }
}
</style>
